const FrameHeader = () => {
    return (
       
        <div className="shadow-t  bg-[#282828] rounded-tl-xl rounded-tr-xl grid-row-1 backdrop-blur-md ">
        <div className="px-1 hidden lg:inline-block">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="inline mx-1 my-2 ">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6 12A6 6 0 1 0 6 0a6 6 0 0 0 0 12z" fill="#FF5E57" />
            </svg>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="inline mx-1 my-2">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6 12A6 6 0 1 0 6 0a6 6 0 0 0 0 12z" fill="#FFBB2E" />
            </svg>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="inline mx-1 my-2">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M6 12A6 6 0 1 0 6 0a6 6 0 0 0 0 12z" fill="#38C149" />
            </svg>

        </div>

    </div>

    )
}
export default FrameHeader;