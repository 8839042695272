
import LandingPage from './Routes/LandingPage';

function App() {
  return (
    <div className="App ">
      <LandingPage className=""/>
    </div>
  );
}

export default App;
