import React from "react";
import Typed from "typed.js";
const LoadProfile = (props) =>{
  
    const el = React.useRef(null);
    const typed = React.useRef(null);
    
    React.useEffect(() => {
      
        const options = {
            strings: ['whoami^1000?'],
            typeSpeed: 300,
            startDelay:1000,
            loop: false,
            cursorChar: '_',
            onComplete: (self) => {
              
               props.ParentCallback()
                self.cursor.remove()
            

            },
        }
        typed.current = new Typed(el.current, options);
        
        return () => {
          // Make sure to destroy Typed instance during cleanup
          // to prevent memory leaks
          typed.current.destroy();
        }
      }, [])
      return (
        <span className=" inline">

          <span className="type-wrap">
            <span style={{ whiteSpace: 'pre' }} ref={el} />
          </span>
        </span>
          )


}
export default LoadProfile;