import { useState, useEffect } from "react";
const NavBar = () => {
    const [Toggle, setToggle] = useState(false);

    function toggle() {
        setToggle(!Toggle)
        setstate({
            fade: 'animate-fadeIn'
        })
    }
    const [state, setstate] = useState({
        fade: ''
    });

    return (

        <div className={`my-4 mx-6 grid place-content-center lg:inline-block`} >

            <div className={`inline-block`}>
                <ul className="text-lg grid grid-row-3 place-content-center">
                    <li className="py-2  inline">
                        <button className="
                        small-nav-btn
                        
                        before:bg-[#282828]
                        before:z-[0]
                        before:absolute
                        before:block
                        before:content-['']
                        
                        before:w-[90px]
                        before:h-[42px]
                        
                        after:bg-[#282828]
                        after:z-[0]
                        after:absolute
                        after:block
                        after:content-['']
                        
                        after:w-[150px] 
                        after:h-[20px]
                        

                        hover:after:w-0
                        hover:before:h-0
                        hover:text-cyan-400
                        hover:border-cyan-400

                       "

                        >
                            <p className="z-10 " >{"Bio "}</p>
                        </button>

                    </li>
                    <li className="py-2 ">
                        <button className="
                        small-nav-btn
                        before:bg-[#282828]
                        before:z-[0]
                        before:absolute
                        before:block
                        before:content-['']
                        
                        before:w-[90px]
                        before:h-[42px]
                        
                        after:bg-[#282828]
                        after:z-[0]
                        after:absolute
                        after:block
                        after:content-['']
                        
                        after:w-[150px] 
                        after:h-[20px]

                        hover:after:w-0
                        hover:before:h-0
                        hover:text-cyan-400
                        hover:border-cyan-400">
                            <p className="z-10">{"Projects "}</p>
                        </button>

                    </li>
                    <li className="py-2 ">
                        <button className="
                        small-nav-btn
                        before:bg-[#282828]
                        before:z-[0]
                        before:absolute
                        before:block
                        before:content-['']
                        
                        before:w-[90px]
                        before:h-[42px]
                        
                        after:bg-[#282828]
                        after:z-[0]
                        after:absolute
                        after:block
                        after:content-['']
                        
                        after:w-[150px] 
                        after:h-[20px]
                        
                        hover:after:w-0
                        hover:before:h-0
                        hover:text-cyan-400
                        hover:border-cyan-400">
                            <p className="z-10">{"Contact "}</p>

                        </button>

                    </li>
                </ul>
            </div>
        </div >
    )
}
export default NavBar;