import ProfileImage from "../Images/test.jpg"
import { useEffect, useState, useRef } from "react";
import NavBar from "./NavBar";
import anime from "animejs";
import SocialMedia from "./SocialMedia";

const ProfileCard = () => {
    const [state, setstate] = useState({
        fade: ''
    });
    useEffect(() => {
        setstate({
            fade: 'animate-fadeIn'
        })
        //window.addEventListener("click", anim)
        return () => {

        };
    }, []);




    return (
        <div className={`grid grid-row-2 lg:flex lg:flex-row place-content-center ` + state.fade}>
            <div className=" flex flex-row place-content-center">
                <div className="overflow-hidden h-[144px] w-[144px] m-10 bg-white rounded-[10em] border-8 border-black lg:hidden">
                    <img src={ProfileImage} className="h-[144px] w-[144px]" />
                </div>
            </div>
            <div className="px-12 lg:pt-[100px]  h-max text-white text-center">
                <h1 className="  lg:text-right text-[32px] lg:text-[58px] font-bold text-[#31B5FF]">
                    Brian Chiu
                </h1>
                <p className=" text-[18px] lg:text-[24px]"> <span className="hidden lg:inline">&gt;</span> Full-Stack Developer</p>
                <NavBar className="lg:pt-10" />
                <p className=" text-red-500 font-bold text-[24px]">
                    Portfolio is WIP!
                </p>
            </div>


            <div className=" hidden lg:flex flex-row place-content-center">
                <div className="overflow-hidden h-[308px] w-[308px] m-10 bg-white rounded-[10em] border-8 border-black">
                    <img src={ProfileImage} className="h-[308px] w-[308px]" />
                </div>
            </div>
        </div>
    )
}
export default ProfileCard;