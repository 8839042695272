import React, { useState } from "react"
import LoadProfile from "../Components/Typed";
import ProfileCard from "../Components/ProfileCard";
import NavBar from "../Components/NavBar";
import FrameHeader from "../Components/FrameHeader";
import SocialMedia from "../Components/SocialMedia";


const LandingPage = () => {
    const [state, setState] = useState(false)
    function handleTypedComplete() {
        setState(true)
    }

    return (
        <div className=" h-screen w-screen grid place-content-center bg-gradient-to-r from-sky-500 to-indigo-600 animate-fadeIn">


            <div className={`sm:rounded-xl w-screen h-screen lg:w-[1000px] lg:h-[641px] bg-[#282828] drop-shadow-2xl`}>

                <FrameHeader className=" w-max h-[28px]" />
                <div >
                    <div className="px-5 py-3 text-white">
                        <p className=" text-[18px] lg:text-[24px] inline">$ &gt; <LoadProfile className="inline" ParentCallback={handleTypedComplete} /></p>
                    </div>
                    <div className={`${state ? "block animate-fade-in" : "hidden"} `}>
                        <ProfileCard />
                        <SocialMedia />
                    </div>


                </div>


            </div>
        </div >
    )
}
export default LandingPage;